// custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"
// normalize CSS across browsers
import "./src/css/normalize.css"
// add bootstrap
// import 'bootstrap/dist/css/bootstrap.min.css';
import './src/styles/global.css'
// custom CSS styles
import './src/css/main.scss'
import './src/css/fonts.css';

import $ from 'jquery';

window.jQuery = $;
window.$ = $;


// import "./src/css/style.css"
